<template>
  <!--  노션 링크 : https://www.notion.so/InfoguidancePage-7733b7e89dd44cee9e0c1193a6f3e794 -->
  <div>
    <div>
      <breadcrumb :breadcrumb_link="introduce_breadcrumb" />
    </div>
    <div class="header">
      <!--      <h1 class="overlay">인재채용</h1>-->
      <div class="headerimage" />
    </div>

    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="operationguide_body_hedaer col-md-12 mt-5 mb-4">
          <h2 class="text-center"><strong>인재채용</strong></h2>
        </div>
      </div>

      <div class="web-nav">
        <div id="btn-radios-1" class="d-flex justify-content-center categoryRadioGroup">
          <label
            v-for="(category, index) in categories"
            :key="index"
            class="cateBoxContainer"
            :class="{checked: selectedCategory === category.value}"
          >
            <input type="radio" :value="category.value" v-model="selectedCategory" />
            <router-link :to="category.to">
              <div class="cateBox">
                <span>{{ category.label }}</span>
              </div>
            </router-link>
          </label>
        </div>
      </div>
      <div class="mobile-nav mx-auto">
        <div id="btn-radios-2" class="categoryRadioGroup">
          <label
            v-for="(category, index) in categories"
            :key="index"
            class="cateBoxContainer"
            :class="{checked: selectedCategory === category.value}"
          >
            <input type="radio" :value="category.value" v-model="selectedCategory" />
            <router-link :to="category.to">
              <div class="cateBox">
                <span>{{ category.label }}</span>
              </div>
            </router-link>
          </label>
        </div>
      </div>

      <div style="min-height: 35rem" class="">
        <div class="recruit_title">
          <span>FAQ</span>
        </div>

        <div style="margin-top: 1rem" v-for="(faq, index) in faqs" :key="index" @click="selectedFaq = index">
          <div class="outterHeader ml-3 font-weight-bold">
            <span> {{ faq.question }} </span>
          </div>
          <hr />
          <div v-show="selectedFaq === index">
            <div class="answerBox">
              <div v-for="(a, index2) in faq.answer" :key="index2">
                <span> {{ a }} </span>
              </div>
            </div>
          </div>
        </div>

        <div style="padding-bottom: 15rem"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import TransitionCollapseHeight from '@/components/TransitionCollapseHeight';

export default {
  name: 'HrFaq',
  data() {
    return {
      introduce_breadcrumb: {
        toplink: 'HOME',
        prevlink: '인재채용',
        present: 'FAQ',
      },
      selectedFaq: -1,
      faqs: [
        {
          question: '1. 리조트 업종에 입사하기 위해서 제일 중요한 것은 무엇인가요?',
          answer: [
            '고객을 가족같이 생각하는 Service Mind와 업무를 대하는 프로정신, 그리고 패기를 가진 인재를 선호합니다.',
            '고객의 입장에서 생각하고, 고객만족을 위해 문제를 해결할 수 있는 정신이야말로 서비스업종에 종사하는 구성원으로서의 자질이 아닐까 생각합니다.',
          ],
        },

        {
          question: '2. 일반 직무에 대한 채용 경로는 어떻게 되나요?',
          answer: [
            '채용은 홈페이지와 취업사이트를 통한 공개채용을 원칙으로 하며, 결원 발생 시 즉시 공지하고 있으므로 많은 관심 부탁드립니다.',
          ],
        },

        {
          question: '3. 인턴으로 근무하기 위한 자격요건 및 지원방법은 어떻게 되나요?',
          answer: [
            '관련학과 졸업자 또는 졸업예정자는 물론 비전공자까지 접객업무에 대한 Mind만 가지고 계신다면 지원가능합니다.',
            '인턴제도를 통해 입사하는 경우에는 근무 성적 등을 통해 정규직 전환 시 가산점 부여 및 전형 일부를 제외하고 있으며, 채용 공고는 홈페이지를 통해 안내할 예정입니다.',
          ],
        },

        {
          question: '4. 채용 어학점수는 어떻게 되나요?',
          answer: [
            '직무에 따라 요구수준이 다르겠지만, 점수보다 중요한 건 일상적인 대화가 가능할 정도의 회화수준입니다.',
            '그러나, 어학 능력 이외에도 지원자의 인성, Service Mind, 업무 적응 능력 등도 접객업무에는 중요하기 때문에 다방면의 노력을 필요로 합니다.\n',
          ],
        },

        {
          question: '5. 외국어 점수는 꼭 공인된 점수여야 하나요?',
          answer: [
            '외국어 공인 점수는 채용 전형 시 도움이 될 수 있습니다.',
            '하지만, 외국어 가능자의 경우 업무수행에 적합한 수준이라고 판단될 경우에는 공인점수가 없어도 채용이 가능합니다. 입사지원서 상에 이에 대한 내용을 작성해 주시면 면접 시 반영됩니다.',
          ],
        },

        {
          question: '6. 현재 진행 중인 채용에 중복 지원이 가능한가요?',
          answer: [
            '전형이 진행 중인 경우에는 다른 직무로의 지원은 불가능합니다.\n',
            '하지만, 전형 완료 후에는 다른 직무으로의 지원은 가능하오니 참고하시기 바랍니다.\n',
          ],
        },
      ],
      selectedCategory: '5',
      categories: [
        {value: '1', to: '/p/HrAbout', label: '채용안내'},
        {value: '2', to: '/p/HrNotice', label: '채용공고'},
        {value: '3', to: '/p/HrNoticeResult', label: '채용공고결과'},
        {value: '4', to: '/p/HrDbReg', label: '인재DB등록'},
        {value: '5', to: '/p/HrFaq', label: 'FAQ'},
        {value: '6', to: '/p/HrWant', label: '인재상'},
        {value: '7', to: '/p/HrWalfare', label: '복리후생'},
        {value: '8', to: '/p/Notice', label: '공지사항'},
        {value: '9', to: '/p/HrPolicy', label: '개인정보 처리방침'},
      ],
    };
  },
  mounted() {
    this.$store.dispatch('Display/changeName', {name: 'FAQ'});
  },
  components: {
    Breadcrumb,
  },
};
</script>

<style scoped lang="scss">
.itemContainer {
  margin-top: 2rem;
  margin-bottom: 1rem;
  line-height: 1rem;
}
.rowSpace {
  height: 1rem;
}
.colSpace {
  width: 0.5rem;
}
.outterHeader {
  font-size: 0.9rem;
}
.optionText {
  font-size: 0.75rem;
  color: #717171;
}
.categoryRadioGroup {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0px;
}
.cateBoxContainer.checked .cateBox {
  background-color: #343a40;
}

.cateBoxContainer.checked .cateBox span {
  color: white;
}
input[type='radio'] {
  display: none;
}

.cateBox {
  background-color: white;
  border: 1px solid #b7b7b7;
  width: 100%;
  height: 3rem;
  text-align: center;
  cursor: pointer;
  font-weight: 900;
  line-height: 3rem;
}

.cateBox span {
  font-size: 0.9rem;
  user-select: none;
  color: #2f2f2f;
}

//// FAQ

.recruit_title {
  margin-top: 2rem;
  margin-bottom: 4rem;
  font-size: 1.5rem;
  text-align: center;
}

.answerBox {
  background-color: #eeeeee;
  color: #888888;
  padding: 2rem;
  word-break: keep-all;
  font-size: 0.9rem;
  //margin-top: -1rem;
}
.headerimage {
  width: 100%;
  margin: 0;
  content: url('~@/assets/images/main/bg_recruit.png');
}
.header {
  position: relative;
  width: 100%;
  > img {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .web-nav {
    display: table;
    width: 100%;
  }
  .mobile-nav {
    display: none;
  }
  .cateBoxContainer {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .web-nav {
    display: none;
  }
  .mobile-nav {
    display: table;
  }
  .cateBoxContainer {
    width: 33%;
  }
}
@media (max-width: 992px) {
  .headerimage {
    display: none;
  }

  .header {
    position: relative;
    width: 100%;
    padding-top: 5rem;
    > img {
      width: 100%;
    }
  }
}
</style>
